import request from '@/utils/request'
//获取权限
export function get_permissions_list (data) {
    return request({
        url: 'application/get_permissions_list',
        method: 'get',
        params: data
    })
}
//插件列表
export function application_list (data) {
    return request({
        url: 'application/application_list',
        method: 'get',
        params: data
    })
}
//创建应用插件
export function update_application (data) {
    return request({
        url: '/application/update_application',
        method: 'post',
        data
    })
}
//删除应用
export function del_appcenter (data) {
    return request({
        url: '/application/del_appcenter',
        method: 'post',
        data
    })
}
//上架应用
export function update_status (data) {
    return request({
        url: '/application/update_status',
        method: 'post',
        data
    })
}
//获取应用插件信息
export function get_appcenter_info (data) {
    return request({
        url: 'application/get_appcenter_info',
        method: 'get',
        params: data
    })
}
//平台列表
export function get_storelist (data) {
    return request({
        url: 'store/get_storelist',
        method: 'get',
        params: data
    })
}
//申请退款
export function apply_refund (data) {
    return request({
        url: '/application/apply_refund',
        method: 'post',
        data
    })
}

//订单列表
export function get_order_list (data) {
    return request({
        url: 'application/get_order_list',
        method: 'get',
        params: data
    })
}
//上架应用
export function create_order (data) {
    return request({
        url: '/application/create_order',
        method: 'post',
        data
    })
}
//应用列表
export function get_applicationlist (data) {
    return request({
        url: 'application/get_applicationlist',
        method: 'get',
        params: data
    })
}
//修改订单
export function update_order (data) {
    return request({
        url: '/application/update_order',
        method: 'post',
        data
    })
}
// 删除订单
export function del_order (data) {
    return request({
        url: '/application/del_order',
        method: 'post',
        data
    })
}
// 支付订单
export function pay_order (data) {
    return request({
        url: '/application/pay_order',
        method: 'post',
        data
    })
}
//短信签名列表
export function get_signtemplatelist (data) {
    return request({
        url: 'application/get_signtemplatelist',
        method: 'get',
        params: data
    })
}
//审核短信签名模板
export function examine_order (data) {
    return request({
        url: 'application/examine_order',
        method: 'post',
        data
    })
}
//获取订单状态
export function get_orderstatus (data) {
    return request({
        url: 'application/get_orderstatus',
        method: 'get',
        params: data
    })
}

