<template>
    <div>
        <Row :gutter="16" style="margin-bottom: 24px">
            <Col span="4">
                <div>
                    <Input placeholder="请输入应用名称" v-model="centerSearch.title"/>
                </div>
            </Col>  
            <Col span="6">
                <div>
                    <Button type="primary" icon="ios-search-outline" @click="searchList">搜索</Button>
                </div>
            </Col>
            </Row>
            <Row :gutter="16" style="margin-bottom: 24px">
            <Col span="6">
                <div>
                    <Button icon="md-add" type="primary"  @click="addAppCenter({ run:'add' })">新增应用</Button>
                </div>
            </Col>
        </Row>
        <Table bcenter :columns="platformColumns" :data="tableData">
            <!-- <template slot-scope="{ row,  }" slot="product_imgs">   
                <img style="width:100px;height:100px" :src="row.product_imgs">
            </template> -->
            <template slot-scope="{ row,  }" slot="operation">   
                <Button  type="primary" size="small" style="margin-right: 5px" @click="editAppCenter(row)" >编辑</Button>     
                <Button  type="error" size="small" style="margin-right: 5px" @click="removeNotice(row.id)">删除</Button>
            </template>
            <template slot-scope="{row} " slot="status">        
                <i-switch v-model="row.status" :value="row.status"  :true-value="1" :false-value="0" size="large" @on-change="changeBan(row)">
                    <span slot="open">已上架</span>
                    <span slot="close">已下架</span>
                </i-switch>
            </template>
            <template slot-scope="{row} " slot="price_group">        
                <div v-for="(item,index) in row.price_group" :key="index">
                    <span v-if="row.permissions_id == 1000">{{item.price}}元/{{item.number}}条</span>
                    <span v-else>{{item.price}}元</span>
                </div>
            </template>
        </Table>
        <br/>
        <Page :total="total" :current="centerSearch.page" style="text-align:right" show-elevator show-total @on-change="pageChange"
            :page-size="centerSearch.limit"/>        
    </div>
</template>
<script>
import { application_list,del_appcenter,update_status } from '@/api/appcenter'
import config from '@/config/index';

export default {
    data() {
        return {
            uploadImg:config.apiUrl ,  
            formData:{
                id: "",
                appId: "",
                name: "",        
                product_imgs: "",
                payUser: "",
                payTime: "",
                price_group: 0,
            },
            platformColumns: [
                {
                    title: '#',
                    key: 'id',
                    width: 80,
                    align:'center'
                },   
                {
                    title: '应用名称',
                    key: 'name',
                    align:'center'
                },
                // {
                //   title: '应用图片',
                //   key: 'product_imgs',
                //   align: 'center',
                //   slot: 'product_imgs',
                // },        
                {
                    title: ' 简介',
                    key: 'introduction',
                    align:'center'
                },
                {
                    title: '价格',
                    key: 'price_group',
                    align:'center',
                    slot: 'price_group', 
                },
                // {
                //   title: '应用详情',
                //   key: 'details_information',
                //   align:'center'
                // },
                {
                    title: '应用状态',
                    key: 'status',
                    align:'center',
                    slot:"status"
                },
                {
                    title: '操作',
                    slot: 'operation',
                    align:'center'
                }
            ],
            tableData:[],
            data6: [],
            showModel: false,
            formItem: {
                title: '',
                introduction: '',
                notice_type: '',
                store_id: '',
                id: '',
            },
            formTitle: 'add',
            centerSearch: {
                limit: 10,
                page: 1,
                title:'',
            },
            unList:[],
            total:0,
        }
    },
    created() {
        this.getList()
        this.getSelect()
    },
    mounted(){
    },
    methods: {
        addAppCenter(e){
            this.$router.push({path:'/admin/appCenter/info', query:e})
        },
        editAppCenter(e){
            e.run = "edit"
            this.$router.push({path:'/admin/appCenter/info', query:e})
        },
        chooseType(e){
            this.centerSearch.type = e
        },
        getSelect(){
        // noticeUn().then(res => {
        //   if (res.code === 200) {
        //     this.unList = res.data
        //   }
        // }).catch(res => {
        //   this.$Message.error(res.msg)
        // })
        },
        changeBan(row){
        let type = row.status == 0 ? 0 : 1
        let data = {status: type,id: row.id}
        update_status(data).then(res => {
            this.$Message.success(res.msg)
            this.getList()
        }).catch(res => {
            this.$Message.error(res.msg)
            this.getList()
        })
        },
        getList() {
            application_list(this.centerSearch).then(res => {
                this.tableData = res.data.data
                this.total = res.data.total  
                this.limit = res.data.limit      
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        pageChange(index) {
            this.centerSearch.page = index
            this.getList()
        },
        searchList(){   
            this.centerSearch.page = 1   
            this.getList()
        },
        addNotice(type) {
            if (type === 'add') {
                this.formTitle = 'add'
            }
            this.showModel = true
        },     
        cancel() {
            this.reset()
        },
        show(index, type, id) {
            console.log(id)
            if (type === 'edit') {
                this.formTitle = 'edit'
            }
            // noticeInfo({id: id}).then(res => {
            //   if (res.code === 200) {
            //     this.formItem.title = res.data.title
            //     this.formItem.visit = res.data.visit
            //     this.formItem.introduction = res.data.introduction
            //     this.formItem.notice_type = res.data.notice_type
            //     this.formItem.introduction = res.data.introduction
            //     this.formItem.id = res.data.id
            //     this.formItem.sort = res.data.sort
            //     this.formItem.hide = res.data.hide
            //   }
            // }).catch(res => {
            //   this.$Message.error(res.msg)
            // })
            this.showModel = true
        },
        removeNotice(id) {
            console.log(id)
            this.$Modal.confirm({
                title: '删除应用',
                content: '请问您是否确认删除该应用中心！！！',
                onOk: () => {
                del_appcenter({id:id}).then(res=>{
                    this.getList()
                    this.$Message.success(res.msg)
                }).catch(res=>{
                    this.$Message.error(res.msg)
                })
                },
                onCancel: () => {
                }
            });
        },
        paySuccessful(id) {
            console.log(id)
            this.$Modal.confirm({
                title: '购买成功',
                content: '确认该订单购买成功吗？',
                onOk: () => {
                //   noticeDel({id:id}).then(res=>{
                //     this.getList()
                //     this.$Message.success(res.msg)
                //   }).catch(res=>{
                //     this.$Message.error(res.msg)
                //   })
                },
                onCancel: () => {
                
                }
            });
        },
        reset(){      
            this.formData.id = ""
            this.formData.appId = ""
            this.formData.name = ""
            this.formData.product_imgs = ""
            this.formData.payUser = ""
            this.formData.payTime = ""
            this.formData.price_group = 0
        }
    }
}
</script>
<style scoped >
.vertical-center-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}
.vertical-center-modal .ivu-modal {
    top: 0;
}
.flex-style {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.form-label {
    padding-right: 12px;
    width: 120px;
    text-align: right;
    font-size: 14px;
    color: #515a6e;
}
/* i-switch 三个汉字 */
::v-deep  .ivu-switch-large.ivu-switch-checked::after {
    left: 45px
}
::v-deep  .ivu-switch-large {
    width: 66px
}
</style>
